
export default function useBuckarooUtils() {
  /**
   * Gets a status message and success state from Buckaroo
   * @param {number} statusCode The Buckaroo status code
   * @returns {Object} status
   */
  const getStatusByCode = (statusCode: string ): PaymentStatus => {
    const statusCodeNumber = parseInt(statusCode, 10)
    const status: PaymentStatus = {
      message: '',
      success: false,
      completed: false,
      title: '',
      code: statusCodeNumber
    }
    switch (statusCodeNumber) {
      case 190:
        status.title = 'De betaling is gelukt!'
        status.message = 'De betaling is gelukt, bedankt!'
        status.success = true
        status.completed = true
        break
      case 490:
      case 491:
      case 492:
        status.title = 'De betaling is afgewezen!'
        status.message = 'Je betaling is niet gelukt. Probeer het opnieuw of verlaat deze pagina.'
        status.success = false
        break
      case 690:
        status.title = 'De betaling is mislukt!'
        status.message = 'Je betaling is niet gelukt. Probeer het opnieuw of verlaat deze pagina.'
        status.success = false
        break
      case 790:
      case 791:
        status.message = 'Je betaling is geregistreerd en wordt later verwerkt.'
        status.success = true
        break
      case 792:
        status.title = 'De betaling is geannuleerd!'
        status.message =
          'Je betaling is geregistreerd, maar je moet nog een handmatige overboeking doen om de betaling te voltooien.'
        status.success = true
        break
      case 890:
      case 891:
      default:
        status.title = 'De betaling is mislukt!'
        status.message = 'Je hebt de betaling gestaakt. Probeer het opnieuw of verlaat deze pagina.'
        status.success = false
        break
    }
    return status
  }

  const getBankIcon = (bank: string) => {
    switch (bank) {
      case 'ABNANL2A':
      case 'BUNQNL2A':
      case 'KNABNL2H':
      case 'RBRBNL21':
      case 'SNSBNL2A':
      case 'ASNBNL21':
      case 'FVLBNL22':
      case 'INGBNL2A':
      case 'RABONL2U':
      case 'REVOLT21':
      case 'TRIONL2U':
      case 'NTSBDEB1':
      case 'NNBANL2G':
      case 'BITSNL2A':
        return `/ideal-bank-icons/${bank}.svg`
      default:
        return '/ideal-bank-icons/generic.svg'
    }
  }

  return {  getStatusByCode, getBankIcon }
}
