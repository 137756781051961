import { useOrderStore } from '@/stores/orderStore'
import type { PaymentInput } from '@eo/graphql-types'
import type { OrderType } from '@/types/orderType'

export default function usePaymentUtils() {
  const orderStore = useOrderStore()

  const isOneTimePayment = () => {
    if (orderStore.mergedProducts.length === 0) {
      return false
    }

    return orderStore.mergedProducts.every(
      (product) => product.durationType === 'Eenmalig',
    )
  }

  const mapToInputProducts = (items) =>
    items.flatMap((item) =>
      item.products?.length > 0 && !item.isSet
        ? mapMultipleToInputProducts(item)
        : [mapSingleToInputProduct(item)],
    )

  // donation gifts can contain multiple products (stored in data.products) under the same data.title
  const mapMultipleToInputProducts = (data) =>
    data.products.map((product: any) => ({
      ...mapSingleToInputProduct(product),
      title: data.title,
    }))

  const mapSingleToInputProduct = (data) => ({
    id: data.id,
    price: data.price,
    isSet: data.isSet,
    discount: data.discount,
    key: data.key,
    title: data.title,
    campaignCode: data.campaignCode ? data.campaignCode.toString() : '',
    durationFirstPeriod: data.durationFirstPeriod,
    durationNextPeriods: data.durationNextPeriods,
    durationUnit: data.durationUnit,
    durationType: data.durationType,
    quantity: data.quantity,
  })

  const mapToPaymentInput = (orderType: OrderType): PaymentInput => ({
    amount: parseFloat(orderStore.amount),
    recurringAmount: parseFloat(orderStore.recurringAmount),
    birthDate: orderStore.formData.dateOfBirth
      ? new Date(orderStore.formData.dateOfBirth).toISOString()
      : null,
    campaignCode: orderStore.campaignCode
      ? orderStore.campaignCode.toString()
      : '',
    email: orderStore.formData.email,
    firstName: orderStore.formData.firstName,
    gender: orderStore.formData.gender,
    middleName: orderStore.formData.middleName || null,
    lastName: orderStore.formData.lastName,
    houseNumber: parseInt(orderStore.formData.homeNumber, 10),
    houseNumberAddition: orderStore.formData.homeNumberAddition,
    postalCode: orderStore.formData.postalCode,
    city: orderStore.formData.cityName,
    street: orderStore.formData.streetName,
    claimsIsMember: !!orderStore.formData.claimsIsMember,
    organisation: 'EO',
    paymentType: orderStore.paymentMethod || 'Other',
    phoneNumber: orderStore.formData.phoneNumber,
    productDescription: orderStore.productDescription,
    description: orderStore.description,
    // use the orderStore.repetition if it's set (by e.g. the donationStore), otherwise calculate whether it's repetitive or not.
    repetition:
      orderStore.repetition ?? (isOneTimePayment() ? 'one-time' : 'repetitive'),
    bank: orderStore.selectedBank,
    orderId: `EO-${Math.floor(new Date().getTime() / 1000)}-${Math.floor(
      Math.random() * 1000,
    )}`,
    products: mapToInputProducts(orderStore.mergedProducts),
    origin: window.location.href,
    iban: orderStore.formData.bankNumber,
    orderType,
    sfCampaign: orderStore.sfCampaign,
    sfCampaignKeyword: orderStore.sfCampaignKeyword,
  })

  return { isOneTimePayment, mapToPaymentInput }
}
