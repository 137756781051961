import type {
  ContentSalesforceProduct,
  SingleSale,
  Subscription,
} from '@eo/graphql-types'
import { defineStore } from 'pinia'
import useOrderUtils from '@/composables/useOrderUtils'
export const useOrderStore = defineStore('orderData', () => {
  const { mapToMergedProducts } = useOrderUtils()
  const { getTotalPriceAsNumber } = usePriceUtils()

  const formData = ref()
  const paymentMethod = ref()
  const selectedBank = ref()
  const statusCode = ref()
  const orderType = ref()
  const campaignCode = ref()
  const description = ref()
  const productDescription = ref()
  const repetition = ref()
  const salesforceProduct = ref()
  const mergedProducts = ref([] as ContentSalesforceProduct[])
  const sfCampaign = ref()
  const sfCampaignKeyword = ref()
  const durationType = ref()

  const amount = computed(() => getTotalPriceAsNumber(mergedProducts.value))
  const recurringAmount = computed(() =>
    getTotalPriceAsNumber(mergedProducts.value, true),
  )

  const setPaymentMethod = (newMethod: string) => {
    paymentMethod.value = newMethod
  }

  const setOrderType = (newOrderType: string) => {
    orderType.value = newOrderType
  }

  const updateFormData = (newData: object) => {
    formData.value = { ...formData.value, ...newData }
    if (newData.bankSelect) {
      selectedBank.value = newData.bankSelect
    }
  }

  const findProductIndex = (product: ContentSalesforceProduct) =>
    mergedProducts.value.findIndex(
      (mergedProduct) => mergedProduct.id === product.id,
    )

  const increaseQuantity = (product: ContentSalesforceProduct) => {
    mergedProducts.value[findProductIndex(product)] = {
      ...mergedProducts.value[findProductIndex(product)],
      quantity:
        (mergedProducts.value[findProductIndex(product)].quantity ?? 1) + 1,
    }
  }

  const decreaseQuantity = (product: ContentSalesforceProduct) => {
    mergedProducts.value[findProductIndex(product)] = {
      ...mergedProducts.value[findProductIndex(product)],
      quantity:
        (mergedProducts.value[findProductIndex(product)].quantity ?? 2) - 1,
    }
  }

  const addOrUpdateProduct = (
    newProduct: ContentSalesforceProduct,
    oldProduct?: ContentSalesforceProduct,
  ) => {
    if (oldProduct) {
      mergedProducts.value = mergedProducts.value.filter(
        (item) => item.id !== oldProduct.id,
      )
    }
    mergedProducts.value.push(newProduct)
  }

  const setSelectedBank = (newBank: string) => {
    selectedBank.value = newBank
  }

  const setPaymentStatusCode = (newStatusCode: string) => {
    statusCode.value = newStatusCode
  }

  const setCampaignCode = (newCampaignCode: string) => {
    campaignCode.value = newCampaignCode
  }

  const setDescription = (newDescription: string) => {
    description.value = newDescription
  }

  const setProductDescription = (newProductDescription: string) => {
    productDescription.value = newProductDescription
  }

  const setRepetition = (newRepetiton: string) => {
    repetition.value = newRepetiton
  }

  const setSalesforceProduct = (newSalesforceProduct: string) => {
    salesforceProduct.value = newSalesforceProduct
  }

  const setMergedProducts = (newMergedProducts: []) => {
    mergedProducts.value = newMergedProducts
  }

  const setSfCampaign = (newSfCampaign: string) => {
    sfCampaign.value = newSfCampaign
  }

  const setSfCampaignKeyword = (newSfCampaignKeyword: string) => {
    sfCampaignKeyword.value = newSfCampaignKeyword
  }

  const setDurationType = (newDurationType: string) => {
    durationType.value = newDurationType
  }

  const removeProduct = (productToRemove: ContentSalesforceProduct) => {
    mergedProducts.value = mergedProducts.value.filter(
      (product) => product.id !== productToRemove.id,
    )
  }

  const newChoiceProduct = (newProduct: ContentSalesforceProduct) => {
    const newMergedProducts = mergedProducts.value.filter(
      (product) => product.choiceProduct !== true,
    )
    newMergedProducts.push({
      ...newProduct.products[0],
      title:
        newProduct.products.length > 0
          ? newProduct.products[0].title
          : newProduct.name,
      choiceProduct: true,
    })
    mergedProducts.value = newMergedProducts
  }

  const initializeMergedProducts = (saleItem: SingleSale | Subscription) => {
    mergedProducts.value = mapToMergedProducts(saleItem)
    description.value = saleItem.title
    productDescription.value = saleItem.title
  }

  const setMembership = (item: SingleSale | Subscription, set: boolean) => {
    const membershipIndex = mergedProducts.value.findIndex(
      (product: ContentSalesforceProduct) =>
        product.key === item.membership?.key,
    )
    if (set && membershipIndex === -1) {
      if (item.membership) {
        mergedProducts.value.push(item.membership)
      }
    } else {
      if (membershipIndex > -1) {
        mergedProducts.value.splice(membershipIndex, 1)
      }
    }
  }

  return {
    amount,
    recurringAmount,
    sfCampaign,
    sfCampaignKeyword,
    campaignCode,
    formData,
    paymentMethod,
    selectedBank,
    statusCode,
    setPaymentMethod,
    setOrderType,
    updateFormData,
    mergedProducts,
    productDescription,
    setSelectedBank,
    description,
    repetition,
    salesforceProduct,
    setPaymentStatusCode,
    setCampaignCode,
    setSfCampaign,
    setSfCampaignKeyword,
    setDurationType,
    setDescription,
    removeProduct,
    setProductDescription,
    setRepetition,
    setSalesforceProduct,
    newChoiceProduct,
    setMergedProducts,
    initializeMergedProducts,
    setMembership,
    addOrUpdateProduct,
    increaseQuantity,
    decreaseQuantity,
  }
})
