import type { SingleSale, Subscription } from '@eo/types'

export default function useOrderUtils() {
  const mapToMergedProducts = (saleItem: SingleSale | Subscription) => {
    if (saleItem.products?.length || saleItem.premiums?.length) {
      return [...saleItem.products, ...saleItem.premiums].map((item) => ({
        ...item,
        ...((item.key.split('|')[2] === 'STD' ||
          item.key.split('|')[2] === 'PREM') && {
          campaignCode: item.campaignCode,
        }),
      }))
    }
    return []
  }
  return { mapToMergedProducts }
}
