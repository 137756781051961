import { FormKitNode, getNode } from '@formkit/core'

export default function useValidationUtils() {

  const errorMessages = {
    dateOfBirthRequired:
      'Controleer je geboortedatum. Deze moet correct ingevuld worden.',
    dateOfBirthIncorrect: 'Let op: De minimum leeftijd is 16 jaar.',
    bankNumber: 'Rekeningnummer is niet correct ingevuld',
    gender: 'Geslacht is niet correct ingevuld.',
    streetName: 'Straatnaam is niet correct ingevuld.',
    cityName: 'Woonplaats is niet correct ingevuld.',
    homeNumberAddition:
      'Deze schrijfwijze van huisnummertoevoeging ondersteunen wij niet.',
    homeNumber: 'Vul je huisnummer correct in om het adres compleet te maken.',
    firstName: 'Voornaam is niet correct ingevuld.',
    middleName: 'Tussenvoegsel is niet correct ingevuld.',
    lastName: 'Achternaam is niet correct ingevuld.',
    email: 'E-mailadres is niet correct ingevuld.',
    phoneNumber: 'Telefoonnummer is niet correct ingevuld.',
    postalCode: 'Controleer je postcode. Deze is ongeldig.',
    membershipConsent: 'Let op: een EO lidmaatschap is verplicht.',
  }

  const isValidPostalCode = (node: FormKitNode) => {
    if (typeof node.value === 'string') {
      return (
        (/^[1-9][0-9]{3}?(?!SA|SD|SS)[A-Z]{2}$/i.test(node.value) &&
          node.value.length <= 6)
      )
    }
    return false
  }

  const isValidIban = (node: FormKitNode) => {
    if (typeof node.value === 'string') {
      // 1. Remove whitespaces and convert to capitals
      let value = node.value.toString().replace(/\s/g, '').toUpperCase()
      // https://nl.wikipedia.org/wiki/International_Bank_Account_Number#structuur
      if (!value || value === '') return false

      // 2. Check length
      if (value.length < 5 || value.length > 34) return false

      // 3. Check for land code
      const regexLand = /^[A-z]{2}/
      if (!regexLand.test(value)) return false

      // 4. Check if control number is numeric
      const regexControlNr = /^.{2}[0-9]{2}/
      if (!regexControlNr.test(value)) return false

      // 5. Check if control number is valid
      const controlNr = Number(value.substring(2, 4))
      if (controlNr < 2 || controlNr > 98) return false

      // 6a. Place land and control at the end
      value = value.substring(4) + value.substring(0, 4)

      // 6b. Convert letters to numbers (A = 11, B = 12, etc)
      let valueNumeric = ''
      for (let i = 0; i < value.length; i++) {
        const convertedChar = value[i].match(/[A-Z]/i)
          ? value.charCodeAt(i) - 55
          : value[i]
        valueNumeric += convertedChar
      }
      // 6c. Check if modulo of 97 is one
      // TODO: This part should be rewritten as this is not typescript friendly
      let dividedValue: any = valueNumeric
      const partLength = 10
      while (dividedValue.length > partLength) {
        const part = dividedValue.substring(0, partLength)
        dividedValue = (part % 97) + dividedValue.substring(partLength)
      }
      dividedValue = Number(dividedValue) % 97
      if (dividedValue !== 1) return false

      return true
    }
    return false
  }

  const isValidPhone = (node: FormKitNode) => {
    if (typeof node.value === 'string') {
      if (node.value.length > 0 && node.value.startsWith('+31')) {
        return node.value.length === 12
      }

      return node.value.length === 10
    }
    return false
  }

  return {isValidPostalCode, isValidPhone, isValidIban, errorMessages}
}
