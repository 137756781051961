import { EcommerceEventData } from '@/types/ecommerceEventData'
import { useGtm } from '@gtm-support/vue-gtm'

const {
  getTotalDiscount,
  getTotalPriceAsNumber,
} = usePriceUtils()

export default function useGtmUtils() {
  const getBrandByCommunityName = (communityName: string) => {
    switch (communityName) {
      case 'Eva':
        return 'eva'
      case 'Metterdaad':
        return 'eo-metterdaad'
      case 'Beam':
        return 'beam'
      case 'Visie':
      default:
        return 'EO'
    }
  }

  const sendEcommerceEventData = (ecommerceEventData: EcommerceEventData) => {

    let additionalItemNameData = ''
    additionalItemNameData += ecommerceEventData.paymentMethod ? `, ${ecommerceEventData.paymentMethod}`: ''
    additionalItemNameData += ecommerceEventData.paymentRepetition ? `, ${ecommerceEventData.paymentRepetition}`: ''

    const eCommerceEvent = {
      event: ecommerceEventData.eventName,
      ecommerce: {
        ...(ecommerceEventData.transactionId && { transaction_id: ecommerceEventData.transactionId }),
        affiliation: `EO ${ecommerceEventData.productType} module`,
        value: getTotalPriceAsNumber(
          ecommerceEventData.mergedProducts,
        ),
        currency: 'EUR',
        items: [
          {
            index: 0,
            item_category: `${ecommerceEventData.productType} EO`,  // Main category
            item_brand: ecommerceEventData.brand,
            item_id: ecommerceEventData.mergedProducts[0].key.split('|')[0],
            item_name: ecommerceEventData.mergedProducts
              .map((mergedProduct: { title: string }) => mergedProduct.title)
              .join(', ') + additionalItemNameData,
            discount: getTotalDiscount(ecommerceEventData.mergedProducts),
            price: getTotalPriceAsNumber( // The sale price is the per item list price minus discount
              ecommerceEventData.mergedProducts,
            ),
            quantity: 1,
          },
        ],
      },
    }
    const gtm = useGtm()
    gtm?.trackEvent(eCommerceEvent)
  }
  return { getBrandByCommunityName, sendEcommerceEventData }
}
