import type { ContentSalesforceProduct } from '@eo/graphql-types'

export default function usePriceCalculation() {
  /**
   * Recursive function to get the price for a product
   * When a product contains sub-products, this function will call itself to calculate them as well.
   * @param product
   * @param multiplyByQuantity
   * @returns price
   */
  const getPriceForProduct = (
    product: ContentSalesforceProduct,
    multiplyByQuantity = true,
  ) => {
    let price = 0
    if (product.isSet && product.products?.length) {
      product.products.forEach((setProduct: any) => {
        price += getPriceForProduct(setProduct)
      })
    } else {
      if (product.durationFirstPeriod && product.durationNextPeriods) {
        price =
          product.price *
          (product.durationFirstPeriod / product.durationNextPeriods)
      } else {
        price =
          product.price *
          (multiplyByQuantity && product.quantity ? product.quantity : 1)
      }
      price -= product.discount ?? 0
    }
    return price
  }

  /**
   * Converts a price to readable format
   * @param price
   * @returns string price
   */
  const getReadablePrice = (price: number) => {
    if (price % 1 === 0) {
      return `${price},-`
    }
    return Number(price).toFixed(2).replace('.', ',')
  }

  const getRecurringPaymentProducts = (products: ContentSalesforceProduct[]) =>
    products.filter(
      (product: ContentSalesforceProduct) =>
        'durationType' in product && product.durationType !== 'Eenmalig',
    )

  /**
   * Calculates the total price
   * @param mergedProducts
   * @returns number price
   */
  const getTotalPriceAsNumber = (
    mergedProducts: ContentSalesforceProduct[],
    onlyRecurring = false,
  ) => {
    let price = 0
    if (mergedProducts?.length) {
      let productsToCalculate: ContentSalesforceProduct[] = mergedProducts
      if (onlyRecurring) {
        productsToCalculate = getRecurringPaymentProducts(mergedProducts)
      }
      productsToCalculate.forEach((product) => {
        const productPrice = getPriceForProduct(product)
        if (productPrice) {
          price += productPrice
        }
      })
    }
    return Number(price).toFixed(2)
  }

  const isFree = (mergedProducts: ContentSalesforceProduct[]) =>
    Number(`${getTotalPriceAsNumber(mergedProducts)}`) > 0

  const isDonationOnly = (
    mergedProducts: ContentSalesforceProduct[],
    donationAmount: number,
  ) => {
    return Number(getTotalPriceAsNumber(mergedProducts)) - donationAmount <= 0
  }

  /**
   * Function to get the total discount for the mergedProducts
   * @param ContentSalesforceProduct[]
   * @returns totalDiscount
   */
  const getTotalDiscount = (mergedProducts: ContentSalesforceProduct[]) => {
    const productDiscount = mergedProducts
      .map((i) => i.discount)
      .reduce((a, b) => a + b)
    const productSets = mergedProducts.filter(
      (product) => product.isSet === true,
    )[0]
    let productSetsDiscount = 0
    if (productSets && productSets.products?.length > 0) {
      productSetsDiscount = productSets.products
        .map((i) => i.discount)
        .reduce((a, b) => a + b)
    }
    return productDiscount + productSetsDiscount
  }

  return {
    getPriceForProduct,
    getTotalDiscount,
    getTotalPriceAsNumber,
    isFree,
    isDonationOnly,
    getReadablePrice,
  }
}
